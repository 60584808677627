// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-create-pages-templates-blog-list-tsx": () => import("./../../src/createPages/templates/blog-list.tsx" /* webpackChunkName: "component---src-create-pages-templates-blog-list-tsx" */),
  "component---src-create-pages-templates-blog-post-tsx": () => import("./../../src/createPages/templates/blog-post.tsx" /* webpackChunkName: "component---src-create-pages-templates-blog-post-tsx" */),
  "component---src-create-pages-templates-home-tsx": () => import("./../../src/createPages/templates/home.tsx" /* webpackChunkName: "component---src-create-pages-templates-home-tsx" */),
  "component---src-create-pages-templates-tag-tsx": () => import("./../../src/createPages/templates/tag.tsx" /* webpackChunkName: "component---src-create-pages-templates-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */)
}

